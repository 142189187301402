import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import emailjs from '@emailjs/browser';


export const Contact = () => {
    const { t } = useTranslation();

    const [messageSentState, setMessageSent] = useState(0);
    const [errorMsg, setErr] = useState('initial');

    ///

    const sendMail = (event) => {
          const form = document.getElementById('contact-form');
          setMessageSent(1);
          // event.preventDefault();
          // generate a five digit number for the contact_number variable
          form.contact_number.value = Math.random() * 100000 | 0;
          // these IDs from the previous steps
          emailjs.sendForm('contact_service', 'contact_form', form, 'UxjFLLoYe05cFexNB')
              .then(function() {
                  console.log('Message sent');
                  setErr('sentttttt');
                  setMessageSent(2);
              }, function(error) {
                  console.log('FAILED...', error);
                  setMessageSent(-1);
                  setErr(error);
              });
    }
    ///

    useEffect( () => {
      emailjs.init('UxjFLLoYe05cFexNB');
    }, []);

    if (messageSentState === -1) {
      return (
        <main>
          <strong>{errorMsg}</strong>
        </main>
      )
    }

    if (messageSentState === 1) {
      return (
        <main>
          <div className="container">
            <div className="spinner"/>
          </div>
        </main>
      )
    }

    if (messageSentState === 2) {
      return (
        <>
          <main>
            <div className="container">
              <h1>{t("contact")}</h1>
              <br/><br/><br/>
              <h4>{t("confirm0")}</h4>
              <br/>
              <br/>
              <h3>{t("confirm1")}</h3>
              <br/>
              <h3>{t("confirm2")}</h3>
            </div>
          </main>
        </>
      )
    }

    return (
      <div>
        <br/><br/><br/>
        <main>
        <br/><br/><br/><br/><br/>
          <h1>{t("contact")}</h1>

          <div>
            <form id="contact-form">
                <input type="hidden" name="contact_number" />
                <label>{t("form0")}</label>
                <input type="text" name="user_name" />
                <label>{t("form1")}</label>
                <input type="email" name="user_email" />
                <label>{t("form2")}</label>
                <textarea name="message"></textarea>
                <input id="input-submit" 
                       type="button" 
                       value={t("form3")} 
                       style={{cursor:'pointer'}} 
                       onClick={sendMail}
                       onTouchEnd={sendMail}/>
            </form>
          </div>

        </main>
        <br/><br/><br/>
      </div>
    );
  };
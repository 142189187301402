import * as React from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { MenuItem } from "./MenuItem";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export const Navigation = ({toggle, hidden}) => {
  const { i18n, t } = useTranslation();

  
  const isActive = ({ isActive }) => `link ${isActive ? "active" : "unactive"}`;

  const itemIds = [
    // <NavLink className={isActive} to="/about">
    //   <div onClick={toggle}>{t('about')}</div>
    // </NavLink>,
    <NavLink className={isActive} to='/services'>
      <div onClick={toggle}>{t('services')}</div>
    </NavLink>,
    <NavLink className={isActive} to='/contact'>
      <div onClick={toggle}>{t('contact')}</div>
    </NavLink>];

  return(
  <motion.ul variants={variants} hidden={hidden}>
    {itemIds.map((content, i) => (
      <MenuItem i={i} key={i} content={content} />
    ))}
  </motion.ul>
)};



import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from 'i18next-browser-languagedetector';

import texts from './assets/textcontents';
import translations from './assets/translations';

// const contents = { ...texts, ...translations };
//const contents = Object.assign( translations, texts);
const contents = {
  ca: { translation: { ...texts.ca.translation, ...translations.ca.translation }},
  es: { translation: { ...texts.es.translation, ...translations.es.translation }},
  en: { translation: { ...texts.en.translation, ...translations.en.translation }}
}

const i18config = {
    // lng: "ca",
    // fallbackLng: "es",
    returnEmptyString: true,
    interpolation: {
      escapeValue: false,
    },
    detection: {
        // order: ['path'],
        caches: [], // 'localStorage' 'cookie'
        order: ['localStorage', 'navigator'], // 'cookie', 'sessionStorage', 'querystring'
        lookupQuerystring: 'lng'
    },
    resources: contents,
    react: {
      // transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    }
  };

export const reinit = () => {
  const localStorageAllowed = {
    detection: { caches: ['localStorage'], order: ['localStorage', 'navigator'] } 
  };
  console.log(localStorageAllowed);
  const newConfig = { ...i18config, ...localStorageAllowed };

  console.log(newConfig);
  i18n.init(newConfig);
}

i18n
// .use(XHR)
.use(detector)
.use(initReactI18next)
.init(i18config);

export default i18n;
import * as React from 'react';
import { useTranslation, Trans } from "react-i18next";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const PlanCard = ({texts}) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {bull}{t(texts[0])}{bull}
        </Typography>
        <Typography variant="h5" component="div">
          {t(texts[1])}
        </Typography>
        <br/>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {t(texts[2])}
        </Typography>
        <Typography style={{padding: '0px 45px 40px 40px', lineHeight: 1.6}} align="center" variant="body2">
            <Trans t={t}>{t(texts[3])}</Trans>
        </Typography>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}

export default PlanCard;
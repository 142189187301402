export const LANGUAGES = [
    { label: "Català", code: "ca" },
    { label: "Español", code: "es" },
    { label: "English", code: "en" },
  ];

export const mail = 'info@barcinotech.com';
export const whatsappESCAT = '623978027';

// clients urls
export const espainamurl = 'https://espai-nam.web.app/';
export const veroinkurl = 'https://veroink-4ff4b.web.app/';
import * as React from 'react';
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";

import net from '../../assets/images/iconos/net.png';
import './styles.css';


const Square = ( { title, children, navigation } ) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='headerSquare'>
      
        <img className='icon' src={net}/>
        <h1 className='title'>{t(title)}</h1>
        <div onClick={() => navigate('Contact')} className='contact'>Contact</div>
        <div className='bottomContent'>
          {children}
        </div>
        
    </div>
  );
}

export default Square;
import React from 'react';
import { useTranslation } from "react-i18next";

import { Block } from "../components/Block";
import cris from '../assets/images/Cris.jpeg';


export const About = () => {
  const { t } = useTranslation();
  
    return (
      <main>
        <h2>{t("about")}</h2>
        {/* <Section title='' text='desc2'/> */}
        <Block>
          <div className="team">
            <div>
                <div>
                  <span className="name">Cristina Bosom</span>
                  <p>{t("team0")}</p>
                </div>
                <img height="200" className="foto" src={cris}/>
            </div>
            <br/>
            <hr color="white"/>
            <br/>
            {/* <div>
                <img height="200" className="foto" src={}/>
                <div>
                  <span className="name"></span>
                  <p>{t("team1")}</p>
                </div>
            </div> */}
          </div>
        </Block>
        <br/><br/><br/>
      </main>
    );
  };
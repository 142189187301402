import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";

import Card from "../components/Card";
import js from "../assets/icons/jschroma.png";
import react from "../assets/icons/reactjsicon.webp";
import rn from "../assets/icons/rnicon.png";
import './styles.css';


export const Services = () => {
    const { t } = useTranslation();


    // useEffect( () => {
    //   window.tsParticles
    //     .load({
    //       id: "tsparticles",
    //       // url: './particles.json',
    //       options: { preset: "triangles" }
    //     })
    //     .then(container => {
    //       console.log("Loaded");
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });
    // }, []);


    // card text (keys): suggested for, title, cost, description
    const basic = [
      'ecohosting0a',
      'ecohosting0b',
      'ecohosting0c',
      'ecohosting0d',
    ];
    const wp = [
      'ecohosting1a',
      'ecohosting1b',
      'ecohosting1c',
      'ecohosting1d',
    ];
    return (
      <>
        <main>
          <h1>{t("services")}</h1>

          <div>
            <br/><br/> <br/>
            <h2>🌱 {t("hosting")} 🌱</h2>
            <br/><br/>
            <h3>{t("ecohosting")}</h3>
            <br/>
            <div className='ecohosting'>
              <Card texts={basic} />
              <Card texts={wp} />
              {/* <Card texts={premium} /> */}
            </div>
            
          </div>

          <br/>
          <hr style={{width:'65%'}}/>
          <h3>{t("specialized")}</h3>

          <div className='specialized'>
            <img height="75" src={js} />
            <img height="75" src={react} />
            <img height="75" src={rn} />
          </div>

          <br/><br/>

          <div className='webdev'>
            <hr align="center" style={{width:'70%'}}/>
            <br/>
            <h2>{t("serveis")}</h2>
            <ul>
              <li>
                <span>{t("list0")}</span>
              </li>
              <li>
                <span>{t("list1")}</span>
              </li>
              <li>
                <span>{t("list2")}</span>
              </li>
              <li>
                <span>{t("list3")}</span>
              </li>
              <li>
                <span>{t("list4")}</span>
              </li>
            </ul>
            {/* <span>{t("subnote")}</span> */}
            
            {/* <hr style={{width:'70%'}}/> */}
            {/* <h3>{t("subscriptions")}</h3> */}
          </div>
          
          
          {/* <div className='subscriptions'>
            <Card texts={basic} />
            <Card texts={advanced} />
            <Card texts={premium} />
          </div> */}

          <br/>
          
          {/* <hr align="center" style={{width:'30%'}}/> */}
          
          <br/><br/><br/><br/><br/><br/>

          {/* <hr style={{width:'70%'}}/> */}
          <br/><br/><br/>


        </main>
      </>
    );
  };
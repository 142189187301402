import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

import { Header } from "./components/Header";
import Cookies from "./components/Cookies";
import { About } from "./pages/About";
import { Home } from "./pages/Home";
import { Services } from "./pages/Services";
import { Contact } from "./pages/Contact";

import { optionsjson } from './assets/particles';
// import avatar from './assets/images/logo1.svg';
import {  whatsappESCAT } from "./constants";
import green from './assets/images/Green_15.png';


const App = () => {
  const { i18n, t } = useTranslation();
  // const whatsapp = i18n.resolvedLanguage == 'en' ? whatsappINT : whatsappESCAT;
  const whatsapp = whatsappESCAT;
  const whatsto = `https://api.whatsapp.com/send?phone=${whatsapp}`;
  const call = `tel:+34${whatsapp}`;

  return (
    <BrowserRouter>
      <Cookies/>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <footer>
        <div>
          {/* <a href={call}>
              <LocalPhoneIcon/>
          </a> */}
          <a target="_blank" href={whatsto} rel="noreferrer">
              <WhatsAppIcon/>
          </a>
          <a href="mailto:info@barcinotech.com">
              <MailOutlineIcon/>
          </a>
        </div>
        <div>
            <img src={green}/>
        </div>
        <div>
          <a target="_blank" href="https://twitter.com/barcinotech" rel="noreferrer">
              <XIcon/>
          </a>
          {/* <a target="_blank" href="https://www.facebook.com/profile.php?id=61556006754269">
              <FacebookIcon/>
          </a> */}
          <a target="_blank" href="https://www.instagram.com/barcinotech/" rel="noreferrer">
              <InstagramIcon/>
          </a>
        </div>
      </footer>
      {/* <FloatingWhatsApp phoneNumber={whatsapp} accountName='BarcinoTech' 
        avatar={avatar} statusMessage='' chatMessage={t('chat')}/> */}
    </BrowserRouter>
  );
};
export default App;

const content = {
        ca: {
            translation: {
                title: 'BarcinoTech',
                about: `Sobre nosaltres`,
                services: 'Serveis',
                contact: 'Contacte',
                specialized: 'Especialitzats en les tecnologies:',
                cookies: `Utilitzem cookies per google analytics i preferencies d'idioma`,
                accept: 'Acceptar',
                decline: 'Rebutjar',

                team0: `Desenvolupadora de programari Fullstack amb més de 10 anys d'experiència en diferents plataformes i especialitats. Centrada principalment en JS, React i React Native, ha treballat per a productes de diferents sectors. Orientada a resultats, atenta als detalls i analítica.`,
                team1: `Especialista en tecnologia centrat en el client i orientat als resultats, amb més de 15 anys d'experiència en la indústria digital i passió per oferir solucions eficients. Originari de Nova York, Bill viu a Barcelona des de fa més de 6 anys, ajudant clients i empreses tant en castellà com en anglès.
A BarcinoTech, Bill aporta la seva àmplia experiència en donar suport tant a petites com a grans empreses en totes les seves necessitats tecnològiques.`,
                permail: 'Per e-mail: {{mail}}',
                perwhats: 'Per whats: {{whats}}',
                eslogan: 'Creant a la ciutat comtal',
                /** Oferim desenvolupament de pàgines web mobile-responsive, aplicacions mòbils, aplicacions TV (Apple TV i Android TV), i aplicacions cross-platform amb lògica centralitzada (amb React Native).  */
                desc: `<1>Centralització</1>\nCreiem en la centralització com l'opció més <strong>eficient</strong> per a aplicacions multiplataforma, per la qual cosa apostem per les tecnologies React Native i React Native web per <strong>unificar</strong> la lògica i el codi entre plataformes i diferències les únicament les parts necessàries.`,                
                desc2: `<1>Sostenibilitat</1>\nEstem conscienciats amb el canvi climàtic, i per reduir <strong>l'empremta de carboni</strong> fem servir eco-hosting de la mà de <0>GreenGeeks</0>. Amb això aconseguim que les pàgines web allotjades amb aquest servei siguin <2>300% Green Energy</2>.\n`,
                desc3: `\n\n<1>Missió</1>\nLa nostra principal missió és ajudar a petites empreses a no quedar-se enrere a l'era tecnològica en un context de gran competència, oferint preus assequibles i serveis per subscripció, així com opcions de pagaments a terminis.
                Demana'ns pressupost sense compromís per el teu cas concret, o consulta els nostres plans de subscripció.`,
                clients: '<1>Projectes que ja han confiat en nosaltres</1>',
                products: '<1>Productes</1>',

                hosting: "Eco hosting",
                ecohosting: "Sabíeu que Internet és un dels contaminants més grans del món?",
                ecohosting0a: 'Pàgines estàtiques',
                ecohosting0b: "Bàsic",
                ecohosting0c: '7€ mensuals',
                ecohosting0d: `
- Allotjament ecològic i d'alt rendiment amb Greengeeks
- Opció d'afegir el segell ecològic al vostre lloc web


* Obteniu un 10% de descompte per a les subscripcions anuals`,
                ecohosting1a: "Migració CMS",
                ecohosting1b: "Webs de Wordpress",
                ecohosting1c: '8€ mensuals',
                ecohosting1d: `
- Allotjament ecològic i d'alt rendiment amb Greengeeks
- Opció per afegir el segell ecològic al vostre lloc web
- Procés de migració lliure

* Obteniu un 10% de descompte per a les subscripcions anuals`,
                

                serveis: 'Desenvolupament web',
                list0: 'Diseny adaptatiu per móvils',
                list1: 'Multilingüe',
                list2: 'Routing',
                list3: 'Desenvolupament personalitzat',
                list4: 'Hosting sostenible',
                subnote: 'Desde 200€, depenent de la complexitat y requeriments.\nAixò que llegueixes no és el que busques? Parlem-hi!',
                subscriptions: 'Plans de subscripció',
                chat: 'Hola! Com podem ajudar-te?',
                name0: 'Bàsic',
                suggestedFor0: 'Ideal per petits negocis',
                cost0: `Consulta'ns sobre el preu`, //'300€ inicial + 50€ de cuota mensual',
                description0: `
- Pàgina web single page o fins a cinc seccions/subpàgines
- Multilingüe: Castellà, Català i Anglès.
- Gestión de contingut (textual, imatges, entre d'altres )
- Integració de la página web amb les reds socials
- Optimització del posicionamient web y SEO
- 1 cambi substancial general
- Suport por WhatsApp y email de Dilluns a Divendres, desde las 09h fins les 18h
`,
                name1: 'Estàndard',
                suggestedFor1: 'Ideal per mitjanes empreses',
                cost1: `Consulta'ns sobre el preu`, //'500€ inicial + 100€ de cuota mensual',
                description1: `
- Pàgina web single page o fins a set seccions/subpàgines
- Multilingüe: Castellà, Català i Anglès.
- Gestión de contingut (textual, imatges, entre d'altres )
- Integració de la página web amb les reds socials
- Optimització del posicionamient web y SEO
- 5 cambis generals
- Suport por teléfono, WhatsApp y email de Dilluns a Divendres, desde las 09h fins les 18h
`,  /**- Sistema de calendari y reserves */
                name2: 'Premium',
                suggestedFor2: 'Perfecte per grans projectes',
                cost2: `Consulta'ns sobre el preu`, //'700€ inicial + 150€ de cuota mensual',
                description2: `
- Pàgina web single page o fins a set seccions/subpàgines
- 15 cambis generals
- Suport por teléfono, WhatsApp y email de Dilluns a Divendres, desde las 09h fins les 18h
- Blog
- Multilingüe: Castellà, Català, Anglès, i una llengua adicional opcional.
- Gestión de contingut (textual, imatges, entre d'altres )
- Integració de la página web amb les reds socials
- Optimització del posicionamient web y SEO
`, /**- Sistema de calendari y reserves */
                form0: 'Nom:',
                form1: 'Correu electrònic:',
                form2: 'Missatge:',
                form3: 'Enviar',
                confirm0: 'Missatge enviat correctament',
                confirm1: 'Gràcies per contactar amb nosaltres',
                confirm2: `Contestarem el més aviat possible`,
            }
        },
        es: {
            translation: {
                title: 'BarcinoTech',
                about: `Quiénes somos`,
                services: 'Servicios',
                contact: 'Contacto',
                specialized: 'Especializados en las desarrollo con las tecnologías:',
                cookies: 'Usamos cookies para google analytics y preferencias de idioma',
                accept: 'Aceptar',
                decline: 'Rechazar',

                team0: `Desarrolladora de Software Fullstack con más de 10 años de experiencia en diferentes plataformas y stacks. Actualmente centrada principalmente en JS, React y React Native, ha trabajado para productos de diferentes sectores. Orientada a resultados, con atención al detalle y analítica.`,
                team1: `Especialista en tecnología centrado en el cliente y orientado a resultados, con más de 15 años de experiencia en la industria digital y pasión por ofrecer soluciones eficientes. Originario de Nueva York, Bill ha vivido en Barcelona durante más de 6 años, ayudando a clientes y empresas tanto en español como en inglés.
En BarcinoTech, Bill aporta su amplia experiencia apoyando a pequeñas y grandes empresas con todas sus necesidades tecnológicas.`,
                permail: 'Por email: {{mail}}',
                perwhats: 'Per whats: {{whats}}',
                eslogan: 'Creando en la ciudad condal',
                /** Ofrecemos desarrollo páginas web mobile-responsive, aplicaciones móviles, aplicaciones TV (Apple TV y Android TV), y aplicaciones cross-platform con lógica centralizada (con React Native). */
                desc: `<1>Centralización</1>\nCreemos en la centralización como la opción más <strong>eficiente</strong> para aplicaciones multiplataforma, apostando por las tecnologías React Native y React Native web para <strong>unificar</strong> la lógica entre plataformas, y diferenciar únicamente las partes necesarias.`,
                desc2: `<1>Sostenibilidad</1>\nEstamos concienciados con el cambio climático, y para reducir la <strong>huella de carbono</strong> usamos eco-hosting de la mano de <0>GreenGeeks</0>. Con esto logramos que las páginas web alojadas con este servicio sean <2>300% Green Energy</2>.\n`,
                desc3: `\n\n<1></1>\nNuestra principal motivación es ayudar a pequeños negocios a actualizarse a la era tecnológica, en el contexto actual de gran competencia, ofreciendo precios asequibles y servicios por subscripción que permiten un seguimiento, además de opciones de pagos a plazos.
Pídenos presupuesto sin compromiso para tu caso concreto, o consulta nuestros planes de suscripción.`,
                clients: '<1>Proyectos que ya han confiado en nosotros:</1>',
                products: '<1>Productos</1>',
                hosting: `Eco Hosting`,
                ecohosting: "¿Sabías que Internet es uno de los mayores contaminantes del mundo?",
                ecohosting0a: 'Páginas estáticas',
                ecohosting0b: 'Básico',
                ecohosting0c: '7€ mensuales',
                ecohosting0d: `
- Alojamiento de alto rendimiento y ecológico con Greengeeks
- Opción de agregar el 'sello' ecológico en tu sitio web


* 10% de descuento para suscripciones anuales`,
                ecohosting1a: 'Migración de CMS',
                ecohosting1b: 'Páginas web de WordPress',
                ecohosting1c: '8€ mensuales',
                ecohosting1d: `
- Alojamiento de alto rendimiento y ecológico con Greengeeks
- Opción de agregar el 'sello' ecológico en tu sitio web
- Proceso de migración gratuito

* 10% de descuento para suscripciones anuales`,


                serveis: 'Desarrollo web a medida',
                list0: 'Diseño adaptativo para móviles',
                list1: 'Multilingüe',
                list2: 'Routing',
                list3: 'Desarrollo personalizado',
                list4: 'Alojamiento web sostenible',
                subnote: 'Desde 200€, dependiendo de la complejidad y especificaciones.\n¿Lo que estabas buscando es diferente a lo que mencionamos? ¡Hablemos!',
                subscriptions: 'Planes de subscripción',
                // "user": "My name is: {{name}}"
                chat: 'Hola! ¿Cómo te podemos ayudar?',
                name0: 'Básico',
                suggestedFor0: 'Perfecto para startups',
                cost0: 'Consúltanos sobre el precio', // '300€ inicial + 50€ de cuota mensual',
                description0: `
- Página web single page o con hasta a cinco secciones/subpáginas
- Multilingüe: Castellano, Catalán e Inglés.
- Gestión de contenido (textual, imágenes, etc)
- Integración de la página web con las redes socials
- Optimización del posicionamiento web y SEO
- 1 cambio sustancial general por mes
- Soporte por WhatsApp y email de Lunes a Viernes, desde las 09h fins les 18h
`,
                name1: 'Estándar',
                suggestedFor1: 'Ideal para medianas empresas',
                cost1: 'Consúltanos sobre el precio', // '500€ inicial + 100€ de cuota mensual',
                description1: `
- Página web single page o hasta siete secciones/subpáginas
- Multilingüe: Castellano, Catalán e Inglés.
- Gestión de contenido (textual, imágenes, entre otros)
- Integración de la página web con las reds sociales
- Optimización del posicionamiento web y SEO
- 5 cambios generales
- Soporte por teléfono, WhatsApp y email de Lunes a Viernes, desde las 09h hasta las 18h
`, /** - Sistema de calendario y reservas */
                name2: 'Premium',
                suggestedFor2: 'Perfecto para grandes proyectos',
                cost2: 'Consúltanos sobre el precio', // '700€ inicial + 150€ de cuota mensual',
                description2: `
- Página web single page o hasta siete secciones/subpáginas
- 15 cambios generales
- Soporte por teléfono, WhatsApp y email de Lunes a Viernes, desde las 09h hasta las 18h
- Blog
- Multilingüe: Castellano, Catalán, Inglés y una lengua adicional opcional.
- Gestión de contenido (textual, imágenes, entre otros)
- Integración de la página web con las reds sociales
- Optimización del posicionamiento web y SEO
`, /** - Sistema de calendario y reservas */
                form0: 'Nombre:',
                form1: 'Correo electrónico:',
                form2: 'Mensaje:',
                form3: 'Enviar',
                confirm0: 'Mensaje enviado',
                confirm1: 'Gracias por contactar con nosotros',
                confirm2: `En breve recibirás nuestra respuesta`,
            }
        },
        en: {
            translation: {
                title: 'BarcinoTech',
                about: 'Our team',
                services: 'Services',
                contact: 'Contact',
                specialized: `Custom software development services
~
Specialized in technologies:`,
                cookies: 'We use cookies for Google Analytics and language preferences',
                accept: 'Accept',
                decline: 'Decline',

                team0: `Fullstack software developer with over 10 years of experience developing for multiple platforms, covering various stacks, and working tirelessly to bring ideas to digital fruition.
A native of Barcelona, Cris began BarcinoTech to assist diverse projects, beginning in her home city, to stay relevant and competitive in the ever changing technological world.`,
                team1: `Client focused and results oriented technology specialist with over 15 years experience across the digital industry and a passion for delivering sustainable solutions.
Originally from New York, Bill has lived in Barcelona for over 6 years, helping clients and businesses in both Spanish and English. At BarcinoTech, Bill brings his extensive experience in supporting both small and large businesses with all of their technological needs.`,
                permail: 'E-mail: {{mail}}',
                perwhats: 'WhatsApp: {{whats}}',
                eslogan: 'Creating from Barcelona',
                /** Optimizado para dispositivos móviles */
                /** We offer mobile-responsive web development, mobile applications, TV applications (Apple TV and Android TV), and cross-platform applications with centralized logic (with React Native).*/
                desc: `<1>Centralization</1>\nWe believe in centralization as the most <strong>efficient</strong> option for cross-platform applications, which is why we rely on React Native and React Native web technologies to <strong>unify</strong> the logic and code between platforms and only differentiate the parts necessary.`,                
                desc2: `<1>Sustainability</1>\nWe are aware of climate change, and to reduce our <strong>carbon footprint</strong> we use eco-hosting with <0>GreenGeeks</0>, making the web pages hosted with this service <2>300% Green Energy</2>.\n`,                
                desc3: `\n\n<1>Mission</1>\nOur main mission is to help small businesses to not be left behind in the technological era in the context of great competition, while offering affordable prices and subscription services, as well as deferred payment plans.\n
Please ask us for a budget for your specific case or feel free to consult our subscription plans. We’ll be happy to assist you!`,
                clients: '<1>Some clients:</1>',
                products: '<1>Products</1>',

                serveis: 'Web development',
                list0: 'Mobile view adapted',
                list1: 'Multilingual',
                list2: 'Routing',
                list3: 'Bespoke development',
                list4: 'Eco-friendly hosting',
                subnote: `From 200€, depending on the complexity and requirements.\nThese specs doesn't fits what you're searching for? Let's talk!`,
                subscriptions: 'Subscription plans',
                chat: 'Hello there! 🤝 \nHow can we help?',

                hosting: `Eco hosting`,
                ecohosting: `Did You Know The Internet Is One Of The World's Largest Polluters?`,
                ecohosting0a: 'Static pages',
                ecohosting0b: 'Basic',
                ecohosting0c: '7€ monthly',
                ecohosting0d: `
- High performance and ecofriendly hosting with Greengeeks
- Option to add the eco stamp on your website


* Get 10% disscount for annual subscriptions`,
                ecohosting1a: 'CMS migration',
                ecohosting1b: 'Wordpress websites',
                ecohosting1c: '8€ monthly',
                ecohosting1d: `
- High performance and ecofriendly hosting with Greengeeks
- Option to add the eco stamp on your website
- Free migration process

* Get 10% disscount for annual subscriptions`,

                name0: 'Basic',
                suggestedFor0: 'Perfect for small businesses',
                cost0: 'Ask us about the price', //'€300 initial + €50 monthly fee',
                description0: `
- Single page web page or with up to five sections/subpages
- Multilingual: Spanish, Catalan and English.
- Content management (textual, images, etc.)
- Integration of the website with social networks
- Optimization of web positioning and SEO
- 1 general substantial change per month
- Support by WhatsApp and email from Monday to Friday, from 9 a.m. to 6 p.m.
`,
                name1: 'Standard',
                suggestedFor1: 'Ideal for medium-sized companies',
                cost1: 'Ask us about the price', //'€500 initial + €100 monthly fee',
                description1: `
- Single page web page or up to seven sections/subpages
- Multilingual: Spanish, Catalan and English.
- Content management (textual, images, among others)
- Integration of the website with social networks
- Optimization of web positioning and SEO
- 5 general changes
- Support by telephone, WhatsApp and email from Monday to Friday, from 09:00 to 18:00
`, /** - Calendar and reservation system */
                name2: 'Premium',
                suggestedFor2: 'Perfect for large projects',
                cost2: 'Ask us about the price', //'€700 initial + €150 monthly fee',
                description2: `
- Single page web page or up to seven sections/subpages
- 15 general changes
- Support by telephone, WhatsApp and email from Monday to Friday, from 09:00 to 18:00
- Blog
- Multilingual: Spanish, Catalan, English, and an optional additional language.
- Content management (textual, images, among others)
- Integration of the website with social networks
- Optimization of web positioning and SEO
`,  /** - Calendar and reservation system */
                form0: 'Name:',
                form1: 'E-mail:',
                form2: 'Message:',
                form3: 'Send',
                confirm0: 'Message sent',
                confirm1: 'Thank you for contact us!',
                confirm2: `We'll reply you soon`,
            },
        },
  };

export default content;
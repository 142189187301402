import React from 'react';

import { Section } from "../components/Section";


export const Block = ({children}) => {

  return (
    <div className="block">
        {children}
    </div>
  );
  
};
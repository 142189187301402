const translations = {
    ca: {
        translation: {
            filter: {
                title: 'Personalització',
                text: `Tu ho somies, nosaltres ho creguem.
Adaptar-nos a les diferents necessitats i crear productes basats en les teves idees és el que fem millor.`,
            },
            eco: {
                title: 'Sostenibilitat',
                text: `Com un dels nostres valors fonamentals, creiem en un enfocament sostenible per als serveis de desenvolupament web, reduint la bretxa entre la tecnologia i les bones pràctiques ambientals per reduir el consum humà i la petjada de carboni.`,
            },
            central: {
                title: 'Centralització',
                text: `Posem focus en la centralització com lʻopció més eficient per a aplicacions multiplataforma, utilitzant les tecnologies React Native i React Native Web per obtenir resultats més consistents i escalables, així com una millor experiència dʻusuari.`,
            }
        }
    },
    en: {
        translation: {
            filter: {
                title: 'Personalization',
                text: `You dream it, we code it.
Adapting to different needs and building products based on your input is what we do best.`,
            },
            eco: {
                title: 'Sustainability',
                text: `As one of our core values, we believe in sustainable approach to web development services, bridging the gap between technology and good environmental practices to reduce pur, and ours clients' carbon footprint.`,
            },
            central: {
                title: 'Centralization',
                text: `We focus on centralization as the most efficient option for cross-platform applications, using React Native and React Native Web technologies for more consistent and scaleable results, as well as better user experience.`,
            }
        }
    },
    es: {
        translation: {
            filter: {
                title: 'Personalización',
                text: `Tú lo sueñas, nosotros lo creamos.
Adaptarnos a las diferentes necesidades y crear productos basados en tus ideas es lo que mejor hacemos.`,
            },
            eco: {
                title: 'Sostenibilidad',
                text: `Como uno de nuestros valores fundamentales, creemos en un enfoque sostenible para los servicios de desarrollo web, reduciendo la brecha entre la tecnología y las buenas prácticas ambientales para reducir el consumo humano y la huella de carbono.`,
            },
            central: {
                title: 'Centralización',
                text: `Ponemos foco en la centralización como la opción más eficiente para aplicaciones multiplataforma, utilizando las tecnologías React Native y React Native Web para obtener resultados más consistentes y escalables, así como una mejor experiencia de usuario.`,
            }
        }
    }

};

export default translations;